import React from 'react';
import './secundary_button.scss'

const SecundaryButton = ({ text, onClick }) => {
  return (
    <button className="secundary-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default SecundaryButton;
