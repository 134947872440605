import React, { useState, useEffect } from 'react';
import InputText from '../components/form/input_text';
import PrimaryButton from '../components/form/primary_button';
import LoginIconButton from '../components/login/icon_button';
import logo from '../assets/logo.svg'
import loginImg from '../assets/login.svg'
import '../styles/login.scss'
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import Apple from '../assets/apple.svg'
import Facebook from '../assets/facebook.svg'
import Google from '../assets/google.svg';


function Login() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '#F3F5F9';
        };
    }, []);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = () => {
        navigate('/dashboard');
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='container'>
            <div className="side">
                <img className='login-img' src={loginImg} alt="Logo" />
            </div>
            <div className="side right-side">
                <div className="login-container">
                    <img className='login-logo' src={logo} alt="Logo" />

                    <div className="login-inputs">

                        <div>
                            <p className='login-label'>E-mail</p>
                            <InputText
                                type="email"
                                placeholder="Entrar com e-mail fornecido"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />

                        </div>

                        <div className="password-input-container">
                            <p className='login-label'>Senha</p>
                            <InputText
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Entrar com senha fornecida"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </div>

                        <div className="login-forgot-pass">
                            <a href="/login/reset-password">Esqueci minha senha</a>
                        </div>

                    </div>
                    <div className='login-buttons'>
                        <PrimaryButton text="Entrar" onClick={handleLogin} />
                        <div class="separator">ou</div>
                        <LoginIconButton text="Entrar com Google" icon={<img src={Google} alt="Google" />} />
                        <LoginIconButton text="Entrar com Facebook" icon={<img src={Facebook} alt="Facebook" />} />
                        <LoginIconButton text="Entrar com Apple" icon={<img src={Apple} alt="Apple" />} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
