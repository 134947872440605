import React from 'react';
import './user_info.scss';

const UserInfo = () => {
    return (
        <div className="user-info">
            <div className="user-info__apresentation">
                <p className="user-info__apresentation_name">Ola Guilherme,</p>
                <p className="user-info__apresentation_data">Hoje e dia 01 de Outubro de 2024</p>
            </div>
            <div className="user-info__profile">
                <div className="user-info__profile_avatar">
                    <img className="user-info__profile_avatar-img" src="https://i.pravatar.cc/150?img=50" alt="perfil" />
                </div>
                <div className="user-info__profile_user">
                    <p className="user-info__profile_user_name">Guilherme Bao</p>
                    <p className="user-info__profile_user_role">Administrador</p>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;
