import React from 'react';
import './icon_button.scss'

const LoginIconButton = ({ text, icon, onClick }) => {
    return (
        <button className="icon-button" onClick={onClick}>
            <div className="terciary-button__text">
                {icon} {text}
            </div>
        </button>
    );
};

export default LoginIconButton;
