import { AddCircleOutline, WhatsApp } from '@mui/icons-material';
import TerciaryButton from '../components/form/terciary_button';
import '../styles/messages.scss'

const MessagesPage = () => {
    return (
        <div className="messages">
            <table className='messages__table'>
                <tr>
                    <th>Mensagem</th>
                    <th>Enviado para</th>
                    <th>Telefone</th>
                    <th>TIpo</th>
                </tr>
                <tr>
                    <td className='td_name'> <span className='td_name_icon'><WhatsApp /></span> Agendamento cancelado</td>
                    <td>Anna Carolina Xavier Moura</td>
                    <td>(31) 99499-0094</td>
                    <td><span className='tag cancel'>Cancelamento</span></td>
                </tr>
                <tr>
                    <td className='td_name'> <span className='td_name_icon'><WhatsApp /></span> Agendamento cancelado</td>
                    <td>Anna Carolina Xavier Moura</td>
                    <td>(31) 99499-0094</td>
                    <td><span className='tag new'>Novo Agendamento</span></td>
                </tr>
            </table>
            <div className="messages__create-button">
                <TerciaryButton
                    text={"Cadastrar nova mensagem"}
                    icon={<AddCircleOutline />} />
            </div>
        </div>
    );
};

export default MessagesPage;