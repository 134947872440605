import React from 'react';
import './description_field.scss'
import { AddPhotoAlternate } from '@mui/icons-material';

const DescriptionField = ({ placeholder, value, onChange }) => {
    return (
        <div className='description-field'>
            <textarea
                rows="10"
                className='input-text'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            <button class="description-field__add-image" type="submit"><AddPhotoAlternate/></button>
        </div>
    );
};

export default DescriptionField;
