import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.scss';
import logo from '../../assets/logo.svg'
import SpaceDashboard from '@mui/icons-material/SpaceDashboard';
import NoteAlt from '@mui/icons-material/NoteAlt';
import Sms from '@mui/icons-material/Sms';
import Group from '@mui/icons-material/Group';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Person from '@mui/icons-material/Person';
import SpaceDashboardOut from '@mui/icons-material/SpaceDashboardOutlined';
import NoteAltOut from '@mui/icons-material/NoteAltOutlined';
import SmsOut from '@mui/icons-material/SmsOutlined';
import GroupOut from '@mui/icons-material/GroupOutlined';
import CalendarMonthOut from '@mui/icons-material/CalendarMonthOutlined';
import PersonOut from '@mui/icons-material/PersonOutline';


const sidebarNavItems = [
    {
        display: 'Dashboard',
        icon: <SpaceDashboardOut />,
        activeIcon: <SpaceDashboard />,
        to: '/dashboard',
        section: ''
    },
    {
        display: 'Dados cadastrais',
        icon: <NoteAltOut />,
        activeIcon: <NoteAlt />,
        to: '/business/profile',
        section: 'NEGÓCIO'
    },
    {
        display: 'Mensagens',
        icon: <SmsOut />,
        activeIcon: <Sms />,
        to: '/business/messages',
        section: 'NEGÓCIO'
    },
    {
        display: 'Clientes',
        icon: <GroupOut />,
        activeIcon: <Group />,
        to: '/business/customers',
        section: 'NEGÓCIO'
    },
    {
        display: 'Agendamentos',
        icon: <CalendarMonthOut />,
        activeIcon: <CalendarMonth />,
        to: '/team/schedules',
        section: 'EQUIPE'
    },
    {
        display: 'Funcionarios',
        icon: <PersonOut />,
        activeIcon: <Person />,
        to: '/team/employees',
        section: 'EQUIPE'
    },
]

const Sidebar = () => {
    let prevSection = '';

    const [activeIndex, setActiveIndex] = useState(0);
    const [activePosition, setActivePosition] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
            indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
        }, 50);
    }, []);

    useEffect(() => {
        const curPath = window.location.pathname;
        const activeItem = sidebarNavItems.findIndex(item => item.to === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
        setTimeout(() => {
            const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item.active');
            var positionY = sidebarItem.offsetTop;
            setActivePosition(positionY);
        }, 50);
    }, [location]);

    return <div className='sidebar'>
        <div className="sidebar__logo">
            <img src={logo} alt="Logo" />
        </div>
        <div ref={sidebarRef} className="sidebar__menu">
            <div
                ref={indicatorRef}
                className="sidebar__menu__indicator"
                style={{
                    position: 'absolute',
                    top: `${activePosition}px`,
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}
            ></div>
            {
                sidebarNavItems.map((item, index) => {
                    let showSection = false;

                    if (prevSection !== item.section) {
                        prevSection = item.section;
                        showSection = true;
                    }

                    return (
                        <div>
                            {showSection && <div className='sidebar__menu__section'>{item.section}</div>}
                            <Link to={item.to} key={index}>
                                <div className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}>
                                    <div className="sidebar__menu__item__icon">
                                        <i>{activeIndex === index ? item.activeIcon : item.icon}</i>
                                    </div>
                                    <div className="sidebar__menu__item__text">
                                        {item.display}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })
            }
        </div>
    </div>;
};

export default Sidebar;