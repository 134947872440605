import './App.css';
import Login from './pages/login';
import Blank from './pages/blank';
import DashboardPage from './pages/dashboard';
import ProfilePage from './pages/profile';
import AppLayout from './components/layout/AppLayout';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MessagesPage from './pages/messages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route index path="/login" element={<Login />} />
        
        <Route path='/' element={<AppLayout />}>
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/business/profile' element={<ProfilePage />} />
          <Route path='/business/messages' element={<MessagesPage />} />
          <Route path='/business/customers' element={<Blank />} />
          <Route path='/team/schedules' element={<Blank />} />
          <Route path='/team/employees' element={<Blank />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
