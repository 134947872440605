import React from 'react';
import './card_dash.scss';
import { LightbulbOutlined, EventBusy, Today, EventAvailable, VisibilityOutlined } from '@mui/icons-material';

const CardDash = ({ type = 'initial', itens = [] }) => {
    const cardTypes = {
        initial: {
            className: 'initial',
            icon: <LightbulbOutlined />,
            message: 'Você não possui nenhum lembrete ou dica',
        },
        empty: {
            className: 'empty',
            icon: <EventBusy />,
            message: 'Não há próximos agendamentos',
        },
        today: {
            className: 'today',
            icon: <Today />,
            message: 'Você tem 5 agendamentos para hoje!',
        },
        available: {
            className: 'available',
            icon: <EventAvailable />,
            message: 'Você tem 5 agendamentos para amanhã!',
        },
    };

    const { className, icon, message } = cardTypes[type] || cardTypes.initial;

    return (
        <div className={`card-dash ${className}`}>
            <span className="card-dash__icon">{icon}</span>
            <p className="card-dash__description">{message}</p>

            {itens.length > 0 && (
                <ul className="card-dash__list">
                    {itens.map((item, index) => (
                        <li key={index} className="card-dash__list-item">
                            <span className="icone"><VisibilityOutlined /></span> <span className="hora">{item.hora}</span> <span className="nome">{item.nome}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CardDash;
