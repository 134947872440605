import React from 'react';
import './terciary_button.scss'

const TerciaryButton = ({ text, icon, onClick }) => {
  return (
    <button className="terciary-button" onClick={onClick}>
      <div className="terciary-button__text">
        {icon && (
          icon
        )}
        {text}
      </div>
    </button>
  );
};

export default TerciaryButton;
