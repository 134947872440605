import CardDash from '../components/dashboard/card_dash';
import '../styles/dashboard.scss'

const DashboardPage = () => {
    const itens = [
        {
            hora: "13:00",
            nome: "Maria Fernanda Tarchi"
        },
        {
            hora: "14:30",
            nome: "Jose Carlos da Silveira"
        },
        {
            hora: "15:30",
            nome: "Guilherme Bao"
        },
        {
            hora: "16:30",
            nome: "Antonio da SIlva Nunes"
        },
        {
            hora: "17:30",
            nome: "Laura Mendonca"
        },
    ]

    return (
        <div className="dashboard">
            <CardDash
                type='available'
                itens={itens} />
            <CardDash
                type='empty' />
        </div>
    );
};

export default DashboardPage;