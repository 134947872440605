import InputText from '../components/form/input_text';
import SecundaryButton from '../components/form/secundary_button';
import DescriptionField from '../components/profile/description_field';
import '../styles/profile.scss';
import { LocationOnOutlined, PhoneEnabledOutlined, Storefront } from '@mui/icons-material';


const ProfilePage = () => {
    return (
        <div className="profile">
            <p className="profile__title">Mantenha seus dados sempre atualizados, essas informações estarão disponíveis no seu site!</p>

            <div className="profile__description">
                <DescriptionField
                    placeholder="Descrição do seu Negócio..."
                    value={""}
                    onChange={(e) => { }} />
            </div>

            <div className="profile__form">
                <div class="row">
                    <div class="cell">
                        <div className="profile__field">
                            <span className="profile__field_icone"><Storefront /></span>
                            <p className="profile__field_label">Segmento de atuação:</p>
                        </div>
                    </div>
                    <div class="cell">
                        <div className="profile__field_input">
                            <InputText
                                type="text"
                                placeholder=""
                                value={""}
                                onChange={(e) => { }} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="cell">
                        <div className="profile__field">
                            <span className="profile__field_icone"><PhoneEnabledOutlined /></span>
                            <p className="profile__field_label">Telefone:</p>
                        </div>
                    </div>
                    <div class="cell">
                        <div className="profile__field_input">
                            <InputText
                                type="text"
                                placeholder="+55"
                                value={""}
                                onChange={(e) => { }} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="cell">
                        <div className="profile__field">
                            <span className="profile__field_icone"><LocationOnOutlined /></span>
                            <p className="profile__field_label">Endereço Comercial:</p>
                        </div>
                    </div>
                    <div class="cell">
                        <div className="profile__field_input">
                            <InputText
                                type="text"
                                placeholder="Digite o CEP"
                                value={""}
                                onChange={(e) => { }} />
                            <div className="search-button">
                                <SecundaryButton text="Pesquisar" onClick={() => { }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;