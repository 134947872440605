import React from 'react';
import './input_text.scss'

const InputText = ({ type, placeholder, value, onChange }) => {
    return (
        <input
            className='input-text'
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    );
};

export default InputText;
